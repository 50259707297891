/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/bootstrap@4.3.1/dist/js/bootstrap.bundle.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
